import request from "@/utils/request";

const BASE_PATH = "/front/basic/exercise/exam/category";
export function getExamCategoryList(query) {
  return request({
    url: BASE_PATH,
    method: "get",
    params: query,
  });
}

export function getExamCategoryById(id) {
  return request({
    url: BASE_PATH + "/" + id,
    method: "get",
  });
}