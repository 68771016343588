import request from "@/utils/request";

const BASE_PATH = "/front/exercise/exam/";
export function getExamList(query) {
  return request({
    url: BASE_PATH,
    method: "get",
    params: query,
  });
}
export function getExamById(id) {
  return request({
    url: BASE_PATH + id,
    method: "get",
  });
}
export function getExamDetails(id) {
  return request({
    url: BASE_PATH + id + "/details",
    method: "get",
  });
}

export function getRecommendSections(topicId, query) {
  return request({
    url: "/front/exercise/topic/" + topicId + "/recommend-sections",
    method: "get",
    params: query,
  });
}
