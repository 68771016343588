<template>
  <div class="EduHome">
    <EduHeader />
    <main class="containerMain">
      <NavInfo />
      <div class="containerMainRight" style="padding-left: 60px">
        <div class="ExaminationCenter">
          <div class="ExaminationCenterTitle" v-if="examCategoryData">
            {{ examCategoryData.name }}
          </div>
          <div class="ExaminationCenterUl">
            <ul>
              <li v-for="(examItem, examIndex) in examData" :key="examIndex">
                <div class="ExaminationCenterUl-img">
                  <img v-if="examItem.coverImage" :src="examItem.coverImage" />
                  <img v-else src="~@/assets/Examination/img-1.jpg" />
                  <!--~@/assets/Examination/img-1.jpg-->
                </div>
                <div class="ExaminationCenterUl-div">
                  <div class="ExaminationCenterUl-title">
                    {{ examItem.title }}
                  </div>
                  <div
                    v-if="examItem.valid == false && examItem.price != 0"
                    class="ExaminationCenterUl-but"
                  >
                    <div class="but1" @click="buy()">立即购买</div>
                  </div>
                  <div v-else class="ExaminationCenterUl-but">
                    <div v-if="examItem.method == 'PRACTICE_QUIZ'">
                      <div
                        class="but2"
                        @click="selectTopicOrAnswerReport(examItem)"
                      >
                        模考
                      </div>
                      <div
                        class="but2"
                        @click="toNewTopic(examItem.id, 'Exercise')"
                      >
                        练习
                      </div>
                    </div>
                    <div v-else-if="examItem.method == 'QUIZ'">
                      <div
                        class="but4"
                        @click="selectTopicOrAnswerReport(examItem)"
                      >
                        模考
                      </div>
                    </div>
                    <div v-else-if="examItem.method == 'PRACTICE_TEST'">
                      <div
                        class="but2"
                        @click="selectTopicOrAnswerReport(examItem)"
                      >
                        测验
                      </div>
                      <div
                        class="but2"
                        @click="toNewTopic(examItem.id, 'Exercise')"
                      >
                        练习
                      </div>
                    </div>
                    <div v-else-if="examItem.method == 'TEST'">
                      <div
                        class="but4"
                        @click="selectTopicOrAnswerReport(examItem)"
                      >
                        测验
                      </div>
                    </div>
                    <div
                      v-else-if="
                        examItem.method == 'PRACTICE' ||
                        examItem.method == null ||
                        examItem.method == ''
                      "
                    >
                      <div
                        class="but4"
                        @click="toNewTopic(examItem.id, 'Exercise')"
                      >
                        练习
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <pay v-show="isPay" ref="pay" @success="paySuccess" @close="closePay" />
    </main>
    <div class="showFile" v-if="fileShow">
      <div class="showFile-info">
        <i @click="fileShow = false"><img src="~@/assets/icon-gb.png" /></i>
        <div class="showFile-info-font">&nbsp;</div>
        <div
          class="showFile-info-button"
          @click="toNewTopic(examId, 'NewTopic')"
        >
          {{ examMethod }}
        </div>
        <div class="showFile-info-h">
          <span @click="toAnswerReport(examId)">查看学习报告</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EduHeader from "@/components/EduHeader";
import NavInfo from "@/components/NavInfo";
import { getExamList } from "@/api/examController";
import { getExamCategoryById } from "@/api/examCategoryController";
import pay from "@/views/Subject/components/Pay/index.vue";
export default {
  name: "EduHome",
  data() {
    return {
      activeName: "Teaching",
      show: false,
      info: "对",
      examData: [],
      queryForm: {
        __page: 0,
        __pageSize: 100,
        __orderBy: "idx",
        published: true,
      },
      isPay: false,
      payIndex: 0,
      scanningCodeUrl: "",
      orderStatusTimer: -1,
      fileShow: false,
      examMethod: "继续模考",
      examId: -1,
      examItem: null,
      categoryId: -1,
      examCategoryData: null,
    };
  },
  created() {
    //alert(this.$store.state.COURSE_ID)
    //const id = this.$store.state.COURSE_ID; //this.$route.params && this.$route.params.id;
    const id = this.$route.params && this.$route.params.id;
    this.categoryId = id;
    this.getExamList();
    this.getExamCategoryById();
  },
  beforeDestroy() {
    window.clearTimeout(this.orderStatusTimer);
  },
  methods: {
    getExamCategoryById() {
      getExamCategoryById(this.categoryId).then((res) => {
        if (res.code == 20000) {
          this.examCategoryData = res.data;
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
    closePay() {
      this.isPay = false;
    },
    paySuccess() {
      this.closePay();
      this.getExamList();
    },
    buy() {
      this.isPay = true;
      this.$refs.pay.showType("list", "");
    },
    getExamList() {
      this.queryForm.categoryId = this.categoryId;
      getExamList(this.queryForm).then((res) => {
        if (res.code == 20000) {
          this.examData = res.data;
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
    selectTopicOrAnswerReport(examItem) {
      this.examItem = examItem;
      this.examId = examItem.id;
      if (examItem.method == "PRACTICE_QUIZ" || examItem.method == "QUIZ") {
        this.examMethod = "继续模考";
      } else if (
        examItem.method == "PRACTICE_TEST" ||
        examItem.method == "TEST"
      ) {
        this.examMethod = "继续测验";
      }
      this.fileShow = true;
    },
    toNewTopic(id, path) {
      var query = null;
      if (this.examItem != null) {
        query = { method: this.examItem.method };
      }
      this.$router.push({
        path: "/" + path + "/" + id,
        query: query,
      });
    },
    toAnswerReport(id) {
      this.$router.push({
        path: "/TrueTopic1/-1", //+ id
        query: { examId: id },
      });
    },
    showBu(row) {
      this.info = row;
      this.show = false;
    },
    showFn() {
      this.show = !this.show;
    },
  },
  components: {
    EduHeader,
    NavInfo,
    pay,
  },
};
</script>

<style lang="scss" scoped>
.containerMain {
  background: #fafafa;
  position: relative;
  .containerMainRight {
    padding-left: 410px;
    .ExaminationCenter {
      width: 1200px;
      margin: 0px auto;
      padding: 75px 0 15px 0;
      .ExaminationCenterTitle {
        font-size: 36px;
        font-weight: 400;
        color: #060606;
        line-height: 60px;
        margin-top: 10px;
      }
      .ExaminationCenterUl {
        ul {
          margin: 0 -15px;
          li {
            cursor: pointer;
            margin: 0 15px;
            float: left;
            width: 274px;
            height: 281px;
            background: #ffffff;
            box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
            border-radius: 6px;
            border: 1px solid #707070;
            margin-top: 25px;
            .ExaminationCenterUl-img {
              font-size: 0;
              img {
                width: 100%;
                height: 135px;
              }
            }
            .ExaminationCenterUl-div {
              padding: 20px 5px;
              text-align: center;
              .ExaminationCenterUl-title {
                font-size: 16px;
                font-weight: 400;
                color: #173d7c;
                line-height: 36px;
              }
              .ExaminationCenterUl-but {
                text-align: center;
                padding-top: 20px;
                .but1 {
                  width: 120px;
                  height: 50px;
                  background: #173d7c;
                  border-radius: 10px;
                  display: inline-block;
                  line-height: 50px;
                  font-size: 16px;
                  font-weight: 400;
                  color: #ffffff;
                  cursor: pointer;
                  margin: 0 5px;
                }
                .but2 {
                  width: 110px;
                  height: 50px;
                  background: #434343;
                  border-radius: 10px;
                  display: inline-block;
                  line-height: 50px;
                  font-size: 16px;
                  font-weight: 400;
                  color: #ffffff;
                  cursor: pointer;
                  margin: 0 5px;
                }
                .but3 {
                  width: 55px;
                  height: 50px;
                  background: #434343;
                  border-radius: 10px;
                  display: inline-block;
                  line-height: 50px;
                  font-size: 16px;
                  font-weight: 400;
                  color: #ffffff;
                  cursor: pointer;
                  margin: 0 5px;
                }
                .but4 {
                  width: 120px;
                  height: 50px;
                  background: #434343;
                  border-radius: 10px;
                  display: inline-block;
                  line-height: 50px;
                  font-size: 16px;
                  font-weight: 400;
                  color: #ffffff;
                  cursor: pointer;
                  margin: 0 5px;
                }
              }
            }
          }
        }
        &::after {
          clear: both;
          display: block;
          content: "";
        }
      }
    }
  }
}
.NewPayShow {
  background: rgba($color: #000000, $alpha: 0.6);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 80px 30px 20px 30px;
  .NewTopicInfo {
    border-radius: 6px;
    background: #ffffff;
    width: 830px;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
    padding-top: 35px;
    padding-bottom: 35px;
    .infoTitle {
      margin: 0 35px;
      padding: 15px 20px;
      background: #ffcf00;
      color: #000;
      border-radius: 6px;
      text-align: center;
      .title {
        font-size: 24px;
        font-weight: bold;
        color: #000;
        line-height: 50px;
      }
      .title-font {
        font-size: 16px;
        font-weight: 400;
        color: #000;
        padding-bottom: 10px;
      }
    }
    .warBr {
      border: 1px solid #cccccc;
      padding: 0px;
      margin-top: 20px;
      text-align: center;
      width: 40%;
      img {
        width: 100%;
      }
    }
    .warBr-title {
      text-align: center;
      line-height: 36px;
    }
    .NewTopic-ul {
      padding-top: 30px;
      ul {
        li {
          padding: 12px 30px;
          &::after {
            clear: both;
            display: block;
            content: "";
          }
          &:hover {
            background: #f2f2f2;
          }
        }
      }
    }
    .close {
      border-radius: 50%;
      width: 24px;
      height: 24px;
      background: url("~@/assets/icon-gb.png") no-repeat center;
      background-size: cover;
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}

.gmBut {
  height: 50px;
  background: #173d7c;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  line-height: 50px;
}
.lh50 {
  line-height: 50px;
  font-size: 16px;
  font-weight: bold;
  color: #ff4600;
  span {
    height: 16px;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    margin-left: 15px;
    font-weight: normal;
  }
}
.font-1 {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 28px;
}
.font-time {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
}
.lh50bf {
  line-height: 50px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  input {
    float: left;
    margin-top: 20px;
    margin-right: 10px;
  }
}
.borC {
  border-top-left-radius: 0 !important;
}
.tasBut {
  float: left;
  span {
    cursor: pointer;
    width: 24px;
    height: 24px;
    float: left;
    line-height: 24px;
    text-align: center;
    margin-top: 13px;
  }
}
</style>

<style>
.itemSpan p,
.inWor p {
  display: inline;
  white-space: pre-line;
}
.SorDdTitle .el-radio__input,
.SorDdTitle .el-checkbox__input {
  float: left;
  margin-top: 2px;
}
.SorDdTitle .el-radio__label,
.SorDdTitle .el-checkbox__label {
  display: block;
  padding-left: 20px;
  line-height: 20px;
}
.showFile {
  background: rgba(67, 67, 67, 0.8);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  box-sizing: border-box;
  padding: 0 50px;
  z-index: 999;
}
.showFile-info {
  width: 630px;
  height: 380px;
  background: #ffffff;
  border-radius: 5px;
  margin: 0 auto;
  position: relative;
  top: 50%;
  margin-top: -210px;
}
.showFile-info i {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
.showFile-info i img {
  cursor: pointer;
  width: 24px;
  height: 24px;
}
.showFile-info-font {
  text-align: center;
  font-size: 24px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 36px;
  padding: 75px 30px 20px 30px;
}
.showFile-info-font span {
  color: #ff6600;
}
.showFile-info-button {
  width: 344px;
  height: 50px;
  background: #173d7c;
  border-radius: 6px;
  margin: 0 auto;
  line-height: 50px;
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}
.showFile-info-button:hover {
  background: #10408f;
}
.showFile-info-h {
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  margin-top: 20px;
  text-align: center;
}
.showFile-info-h span {
  border-bottom: 1px solid #666666;
  display: inline-block;
  cursor: pointer;
  line-height: 20px;
}
</style>
